<template>
    <div class="button-editor-container">
       
        <p style="line-height:1" class="mt-2 mb-1">Button Label</p>
        <a-input v-model="form.label" />

        <p style="line-height:1" class="mt-2 mb-1">Button Link</p>
        <a-input v-model="form.url" :disabled="form.url == '%recipient.unsub%'" />

		<p style="line-height:1" class="mt-3 mb-1">Unsubscribe Button?</p>
		<a-switch :default-checked="form.url == '%recipient.unsub%'" @change="onChange" />
    </div>

</template>

<script>
export default {
    props:{
        value:{
            type:Object,
            required:true,
            default: () => {}
        },
    },
    data(){
        return {
            form:{
                url:'',
                label:'',
            },
			unsub:false
        }
    },
    watch:{
        form:{
            deep:true,
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        },
    },
	methods:{
		onChange(e){
			if (e){
				this.form.url = '%recipient.unsub%'
			} else {
				this.form.url = ''
			}
		}
	},
    created(){
        let value = this.value 
        if (!value || !value.url) value = {url:'',label:''}
        this.form = value
    },
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>